<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog :value="true" max-width="550" transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-icon color="white" small class="close-submit-dialog" @click="close">$close</v-icon>
      <v-list class="pa-0">
        <v-img class="p-abs img-header" src="@/assets/img/login-default-bg.png" min-width="250" width="100%" max-height="70px" />
        <div class="title-header">
          <v-list-item-title class="text-h5 font-weight-light white--text px-5 p-rel white--text pt-2">Formulaire</v-list-item-title>
          <v-list-item-subtitle class="text-caption font-alt mb-1 px-5 white--text p-rel">
            {{ form ? 'Editer le formulaire' : 'Créer un nouveau formulaire' }}
          </v-list-item-subtitle>
        </div>
        <div class="splitter" />
        <v-form ref="form" lazy-validation class="mt-2" @submit.prevent="submit">
          <v-row class="ma-0">
            <v-col cols="12">
              <v-text-field v-model="model.label" hide-details="auto"
                            outlined dense rounded label="Nom du formulaire" :rules="[$rules.required, $rules.maxLength(200), $rules.minLength(2)]"
              />
            </v-col>
            <v-col cols="12">
              <v-select :value="$store.state.establishments.activeEstablishmentId" label="Etablissement" color="secondary" outlined dense rounded hide-details="auto" disabled
                        item-text="label" item-value="id" return-object :items="[$store.getters['establishments/activeEstablishment']]"
              />
            </v-col>
          </v-row>
          <v-card-actions class="px-3">
            <v-spacer />
            <v-btn x-small rounded text @click="close">
              Annuler
            </v-btn>
            <v-btn x-small rounded color="success" type="submit" :loading="submitting" :disabled="submitting">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import {create} from '@/modules/forms/api/forms'
  import api from '@/services/api'

  export default {
    name: 'FormDialog',
    props: {
      form: Object
    },
    data() {
      return{
        model : {
          label: this.form ? this.form.label : ''
        },
        submitting: false
      }
    },
    methods: {
      async submit() {
        if (!this.$refs.form.validate()) {
          return false
        }
        try {
          this.submitting = true
          let data = {...this.model}
          data.establishment = this.$store.getters['establishments/activeEstablishment']['@id']
          let results = this.form ? await api.put(this.form['@id'],data) :  await create(data)
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: this.form ? 'Formulaire modifié avec succès.' : 'Formulaire crée avec succès.'
          })
          if(!this.form) {
            this.$emit('add-form', results.data)
          }else {
            this.$emit('update-form', results.data)
          }
          this.close()
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.submitting = false
        }
      },
      close() {
        this.$emit('close')
      }
    }
  }
</script>
