<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-x">
    <v-card-title class=" font-alt pb-3">Formulaires</v-card-title>
    <v-card-subtitle class="o-60">Liste des formulaires activés et désactivés</v-card-subtitle>
    <!--    <div class="search-list">-->
    <!--      <v-btn fab x-small color="grey lighten-3" class="elevation-x">-->
    <!--        <font-awesome-icon :icon="['fad', 'search']" size="lg" />-->
    <!--      </v-btn>-->
    <!--    </div>-->
    <skeleton-list v-if="loading" />
    <v-list v-if="forms && !loading" class="pa-0">
      <v-list-item two-line class="click-to-create" :class="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                   @click="formCreate = true"
      >
        <v-list-item-avatar>
          <v-avatar size="40" color="success lighten-1">
            <font-awesome-icon :icon="['fas', 'plus']" size="1x" class="white--text" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-alt font-weight-medium">Ajouter un formulaire</v-list-item-title>
          <v-list-item-subtitle class="op-60 font-weight-light">Créer un nouveau formulaire</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <empty-list v-if="forms.length === 0" title="Aucun formulaire"
                  subtitle="L'établissement ne possède aucun formulaire."
      />
      <template v-for="(form, index) in forms">
        <v-list-item :key="form.id" two-line :to="{ name: 'form-write', params:{id: form.id}}">
          <v-list-item-avatar>
            <v-avatar size="40" :color="activeForm(form) ? 'success' : 'grey lighten-2'" class="white--text">
              <font-awesome-icon v-if="activeForm(form)" :icon="['fad', 'check-double']" size="1x" class="white--text" />
              <font-awesome-icon v-else :icon="['fas', 'times']" size="1x" class="white--text" />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-alt">{{ form.label }}</v-list-item-title>
            <v-list-item-subtitle class="op-60 font-weight-light">{{ activeEstablishment.label }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index < forms.length -1" :key="index" />
      </template>

      <div v-if="totalItems > itemsPerPage" class="py-3 d-flex justify-center">
        <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="5"
          color="grey"
          @input="loadForms"
        />
      </div>
    </v-list>
    <form-create v-if="formCreate" @close="closeDialog" @add-form="addListItem" />
  </v-card>
</template>

<script>
  import SkeletonList from '@/modules/core/components/SkeletonList'
  import EmptyList from '@/modules/core/components/EmptyList'
  import FormCreate from '@/modules/forms/components/FormDialog'
  import * as forms from '@/modules/forms/api/forms'
  import { mapGetters } from 'vuex'

  export default {
    name: 'FormsList',
    components: {FormCreate, EmptyList, SkeletonList},
    data() {
      return {
        loading: false,
        forms: null,
        formCreate: false,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        totalItems: 0
      }
    },
    computed: {
      ...mapGetters('establishments', ['activeEstablishment']),
      activeForm() {
        return form => {
          return form['@id'] === this.activeEstablishment.activeForm
        }
      }
    },
    watch: {
      activeEstablishment: {
        handler() {
          this.page = 1
          this.loadForms()
        },
        immediate: true
      }
    },
    methods: {
      async loadForms() {
        let establishmentId = this.$store.state.establishments.activeEstablishmentId
        if(!establishmentId) {
          return
        }
        this.loading = true
        try {
          await forms.list({
            params: {
              page: this.page,
              itemsPerPage: this.itemsPerPage,
              establishment: this.$store.state.establishments.activeEstablishmentId
            }
          }).then((response) => {
            this.forms = response.data['hydra:member']
            this.totalItems = response.data['hydra:totalItems']
            this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
          })
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.loading = false
        }
      },
      addListItem(data) {
        if (data) {
          this.forms.push(data)
        }
      },
      closeDialog() {
        this.formCreate = false
      }
    }
  }
</script>
